import store from "@/store";

import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersCreate() {
  const { router } = useRouter();
  const toast = useToast();

  const sendPasswordResetEmail = (userEmail) => {
    store
      .dispatch("auth/sendPasswordResetEmail", {
        email: userEmail,
      })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Email de création envoyé",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
      })
      .catch((error) => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Erreur lors de l'envoi du mail de création",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error.message,
            },
          },
          { position: "top-center" }
        );
      });
  };

  const createNewUser = (newUser) => {
    store
      .dispatch("app-user/createUser", { newUser })
      .then(() => {
        sendPasswordResetEmail(newUser.email);
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Création réussie",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        // redirect to list page
        router.push({ name: "user-list" });
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Erreur création utilisateur",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch("app-user/fetchCustomers", {})
      .then((response) => {
        const customers = response.data.companies;

        callback(customers);
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error fetching customers list: ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    createNewUser,
    fetchCustomers,
  };
}
