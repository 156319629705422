<template>
  <b-row class="mt-1">
    <b-col cols="12">
      <user-create-form-wizard :create-user="newUser" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import UserCreateFormWizard from "./UserCreateFormWizard.vue";
import userStoreModule from "../userStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    UserCreateFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const newUser = ref({});

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    return {
      newUser,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
