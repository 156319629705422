var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":function($event){return _vm.submitForm(_vm.createUser)}}},[_c('tab-content',{attrs:{"title":"Utilisateur","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":_vm.resolveAvatarLink(
                    _vm.createUser.first_name,
                    _vm.createUser.last_name
                  ),"size":"104px","rounded":""}}),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.createUser.first_name)+" "+_vm._s(_vm.createUser.last_name)+" ")])])])],1)])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"Nom","vid":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false : null,"placeholder":"Bob"},model:{value:(_vm.createUser.last_name),callback:function ($$v) {_vm.$set(_vm.createUser, "last_name", $$v)},expression:"createUser.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom","label-for":"prénom"}},[_c('validation-provider',{attrs:{"name":"Prénom","vid":"Prénom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null,"placeholder":"Dubois"},model:{value:(_vm.createUser.first_name),callback:function ($$v) {_vm.$set(_vm.createUser, "first_name", $$v)},expression:"createUser.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Genre","label-for":"genre","label-class":"mb-1"}},[_c('validation-provider',{attrs:{"name":"Genre","vid":"Genre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"gender","options":_vm.genderOptions,"value":"male"},model:{value:(_vm.createUser.gender),callback:function ($$v) {_vm.$set(_vm.createUser, "gender", $$v)},expression:"createUser.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"martin.dupont@email.com"},model:{value:(_vm.createUser.email),callback:function ($$v) {_vm.$set(_vm.createUser, "email", $$v)},expression:"createUser.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Mobile","label-for":"mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","vid":"Mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registration_number","state":errors.length > 0 ? false : null,"placeholder":"+ 33 6 29 29 29 29"},model:{value:(_vm.createUser.mobile),callback:function ($$v) {_vm.$set(_vm.createUser, "mobile", $$v)},expression:"createUser.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Rôle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rôle","label-for":"rôle","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"role","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createUser.role),callback:function ($$v) {_vm.$set(_vm.createUser, "role", $$v)},expression:"createUser.role"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Entreprise","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Sélection de l'entreprise")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-table',{ref:"selectableTable",staticClass:"mb-0",attrs:{"selectable":"","select-mode":"single","items":_vm.fetchCustomers,"fields":_vm.fields,"responsive":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(entreprise)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":"" + _vm.publicPath +
                        "images/customers/" +
                        "" + (data.item.logo)}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.selectedCompany)?_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":"" + _vm.publicPath +
                  "images/customers/" +
                  "" + (_vm.selectedCompany.logo),"size":"104px","rounded":""}}),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.selectedCompany.name)+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('validation-provider',{attrs:{"name":"Emploi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-form-group",attrs:{"label":"Emploi","label-for":"Emploi","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"emploi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.jobRoleOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createUser.job_role),callback:function ($$v) {_vm.$set(_vm.createUser, "job_role", $$v)},expression:"createUser.job_role"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3267626656)})],1)])],1):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }